<template>
    <!-- 农场版本升级 -->
    <div class="program-update el-content">
        <div class="pu-version" v-if="step == 1">
            <div class="pu-version-item" v-for="(item,index) in version" :key="index">
                <div class="header">{{item.server_name}}</div>
                <div class="desc">{{ item.description }}</div>

                <div class="price">
                    ￥<span>{{ item.discount_price ? item.discount_price : item.price }}</span>元/
                    {{ item.expire }}天
                </div>
                <a-button class="pu-btn" @click="jumpPay(item)">立即购买</a-button>
            </div>
            <div class="pu-version-li"></div>
            <div class="pu-version-li"></div>
            <div class="pu-version-li"></div>
        </div>

        <div class="pu-buy" v-if="step == 2 && payForm.upgradeVersion && payForm.order">
            <div class="pu-buy-item">
                <div class="pbi-title">版本对比：</div>
                <div class="pbi-value">
                    <div class="version-tag old-version">{{ payForm.order.old_server.server_name}}</div>
                    <i class="ri-arrow-right-line ri-lg ri-top"></i>
                    <div class="version-tag new-version">{{ payForm.upgradeVersion.server_name }}</div>
                </div>
            </div>
            <div class="pu-buy-item">
                <div class="pbi-title">升级版本：</div>
                <div class="pbi-value">{{ payForm.upgradeVersion.server_name }}</div>
            </div>
            <div class="pu-buy-item">
                <div class="pbi-title">版本描述：</div>
                <div class="pbi-value">{{ payForm.upgradeVersion.description}}</div>
            </div>
            <div class="pu-buy-item">
                <div class="pbi-title">升级价格：</div>
                <div class="pbi-value">
                    <span class="price" >{{ payForm.order.total_price }}</span>元
                </div>
            </div>
            <div class="pu-buy-item">
                <div class="pbi-title">支付方式：</div>
                <div class="pbi-value">
                    <com-select-pay-method @success="e => payForm.pay_method = e" ></com-select-pay-method>
                </div>
            </div>
            <div class="pu-buy-item">
                <div class="pbi-title"></div>
                <div class="pbi-value">
                    <a-space>
                        <a-button @click="step = 1">取消</a-button>
                        <a-button type="primary" @click="createUpgradeOrder(true)">立即升级</a-button>
                    </a-space>
                </div>
            </div>
        </div>

        <!-- 微信支付 -->
        <div v-if="payForm.show.wxpay && payForm.order">
            <com-wxpay-modal
                :qrcode="payForm.show.wx_pay_qrcode"
                :money="payForm.order.total_price"
                @paied='checkPayd'
                @close="payForm.show.wxpay = false"
            >
            </com-wxpay-modal>
        </div>

        <!-- 支付成功 -->
        <div class="cpr-success" v-if="payForm.order_number">
            <div class="cpr-success-icon">
                <div class="icon"><i class="ri-check-line"></i></div>
                <span>支付成功</span>
            </div>
            <p class="cpr-tips">您的订单 {{payForm.order_number}} 已支付成功!</p>
            <a-button type="primary" @click="jumpToHome">返回首页</a-button>
        </div>
    </div>
</template>
<script>
import ComSelectPayMethod from '@/components/pay/com-select-pay-method.vue'
import comWxpayModal from '@/components/pay/com-wxpay-modal.vue'

import appModel from '@/api/saas/app.js'
import { reactive, ref, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import router from '@/router'
let step = ref(1)

export default {
    components:{
        ComSelectPayMethod,
        comWxpayModal
    },

    setup() {
        const state = reactive({
            version:[],
        })
        appModel.getProgramUpgradeVersion(res=>{
            state.version = res.result
        })

        const payData = usePayData()

        return{
            step,
            ...toRefs(state),
            ...toRefs(payData)
        }
    },
}

function usePayData(){
    const payForm = reactive({
        server_id:0,
        order:null,
        upgradeVersion:null,
        pay_method:2,
        show:{
            wxpay:false,
            wx_pay_qrcode:"",
        },
        order_number:"",    //支付成功得到的order_numer
    })

    const options = useRoute().query
    if( options.out_trade_no ){
        payForm.order_number = options.out_trade_no
        step.value = 3
    }

    //获取预订单
    function createUpgradeOrder(is_pay){
        appModel.createUpgradeProgramOrder({server_id:payForm.server_id,is_pay},res=>{
            if( typeof res.server_bak == 'string' ){
                res.server_bak = JSON.parse(res.server_bak)
            }
            if( typeof res.old_server == 'string' ){
                res.old_server = JSON.parse(res.old_server)
            }
            payForm.order = res
            if( is_pay ){
                appModel.shopOrderPay(res.id,'program_order',payForm.pay_method,res=>{
                    console.log("支付结果查询",res)
                    if( res.status == 1 ){
                        payForm.order_number = payForm.order.order_number;
                    }else{
                        payForm.show.wxpay = true;
                        payForm.show.wx_pay_qrcode = res.qr_code;
                    }
                })
            }
        })
    }

    const jumpPay = data=>{
        payForm.server_id = data.server_id
        payForm.upgradeVersion = data
        step.value = 2
        createUpgradeOrder(false)
    }

    //检测是否支付
    const checkPayd = ()=> appModel.checkIsPay(payForm.order.order_number,()=>{
        payForm.order_number = payForm.order.order_number;
        payForm.show.wxpay = false;
        step.value = 3
    })

    const jumpToHome =()=>router.push("/miniapp")

    return { payForm ,jumpPay,createUpgradeOrder,checkPayd ,jumpToHome}
}

</script>

<style scoped lang="scss">
.pu-version{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 48px;
    justify-content: center;
    &-li{
        width: 300px;
        height: 1px;
        margin: 0 48px 48px 0;
    }
    &-item{
        width: 300px;
        height: 400px;
        border: 1px solid #f4f4f4;
        text-align: center;
        border-radius: 12px;
        cursor: pointer;
        transition: all .4s;
        margin: 0 48px 48px 0;

        &:hover{
            transform: scale(1.1);
            box-shadow: 1px 1px 20px #8fb2d2;
        }

        .header{
            width: 100%;
            height: 80px;
            line-height: 80px;
            font-size: 28px;
            background: #1890ff;
            border-radius: 12px 12px 0 0;
            color: #fff;
        }
        .desc{
            font-size: 14px;
            color: #666;
            min-height: 100px;
            margin-top: 24px;
            padding: 0 12px;
        }

        .price{
            font-weight: bold;
            margin-top: 24px;

            span{
                font-size: 32px;
            }
        }

        .pu-btn{
            margin-top: 48px;
            width: 200px;
            border-radius: 40px;
            height: 40px;
            background: #1890ff;
            border: none;
            color: #fff;
        }
    }
}

.pu-buy{
    width: 100%;
    padding: 24px 0;

    &-item{
        width: 500px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        min-height: 48px;
        align-items: center;

        .pbi-title{
            width: 120px;
            text-align: right;
            color: #666;
        }
        .price{
            font-weight: bold;
            color: #FF3366;
            font-size: 16px;
        }

        &:last-child{
            margin-top: 36px;
        }
    }

    .version-tag{
        display: inline-block;
        padding: 4px 12px;
        font-size: 12px;
    }
    .old-version{
        border: 1px solid #ccc;
        color: #999;
    }
    .new-version{
        border: 1px solid #00CC66;
        color: #00CC66;
    }
    .ri-arrow-right-line{
        margin:  0 12px;
    }
}

.cpr-success{
    width: 100%;
    text-align: center;
    padding-top: 48px;

    &-icon{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .icon{
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: #00CC66;
            color: #fff;
            line-height: 40px;
            text-align: center;

            i{
                font-size: 24px;
            }
        }
        span{
            margin-left: 12px;
            font-size: 18px;
            color: #000;
        }
    }

    .cpr-tips{
        margin: 24px 0;
        color: #666;
    }
}
</style>
