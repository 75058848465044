<template>
    <div class="pay-method">
        <div class="method method-ali" @click="changeMethod(2)">
            <img v-if="pay_method == 2" class="method-active" src="@/assets/img/icon-share-selected.png" alt="" />
            <i class="ri-alipay-line" style="color: #2196F3;"></i>
            支付宝支付
        </div>
        <div class="method method-wx" @click="changeMethod(1)">
            <img v-if="pay_method == 1" class="method-active" src="@/assets/img/icon-share-selected.png" alt="" />
            <i class="ri-wechat-pay-line" style="color: #33CC66;"></i>
            微信支付
        </div>
    </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
export default {
    name:"com-select-pay-method",
    setup(props,context) {
        const state = reactive({
            pay_method:2
        })

        const changeMethod = method=>{
            state.pay_method = method
            context.emit("success",method)
        }

        return{
            ...toRefs(state),
            changeMethod
        }
    },
}
</script>

<style scoped lang="scss">
.pay-method{
    display: flex;

    .method{
        width: 160px;
        margin-right: 24px;
        height: 60px;
        line-height: 40px;
        text-align: center;
        font-size: 16px;
        cursor:pointer;
        position: relative;
        border-radius: 2px;

        i{
            font-size: 30px;
            position: relative;
            top: 8px;
        }

        img{
            position: absolute;
            left: 125px;
            top: 25px;
        }
    }
    .method-ali{
        border: 1px solid #0099FF;
    }
    .method-wx{
        border: 1px solid #00CC66;
    }
}
</style>